@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'partials-onsite/fonts';
// @import 'partials-onsite/variables';
// @import 'partials-onsite/mixins';

// $enable-gradients: true;
// $link-color: $link-color !default;
// $link-decoration: none !default;
// $link-hover-color: $text-highlight !default;
// $link-hover-decoration: none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
// $emphasized-link-hover-darken-percentage: 35% !default;
// $border-radius: 0;

// $theme-colors: (
//     'default': #fff,
//     'event': $text-highlight,
// );

// Resolved from webpack
// @import 'bootstrap';

// @import 'partials-onsite/common';
// @import 'partials-onsite/helpers';
// @import 'partials-onsite/layout';
// @import 'partials-onsite/footer';
// @import 'partials-onsite/components';
// @import 'partials-onsite/countdown';
// @import 'partials-onsite/navbar';
// @import 'partials-onsite/live';
// @import 'partials-onsite/forms';
// @import 'partials-onsite/buttons';
// @import 'partials-onsite/landing';
// @import 'partials-onsite/access';
// @import 'partials-onsite/password-meter';
// @import 'partials-onsite/mixins';
// @import 'partials-onsite/event-list';
// @import 'partials-onsite/register-confirm';
// @import 'partials-onsite/other';

.select2-container--default .select2-selection--single {
    border-radius: 0;
    border-color: theme('colors.celeste');
}

.active {
    transform: rotate(45deg);
}
