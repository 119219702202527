@font-face {
    font-family: 'Eugenio Sans';
    src: url('../fonts/EugenioSans-Black.eot');
    src:
        url('../fonts/EugenioSans-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSans-Black.woff2') format('woff2'),
        url('../fonts/EugenioSans-Black.woff') format('woff'),
        url('../fonts/EugenioSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Sans';
    src: url('../fonts/EugenioSans-Regular.eot');
    src:
        url('../fonts/EugenioSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSans-Regular.woff2') format('woff2'),
        url('../fonts/EugenioSans-Regular.woff') format('woff'),
        url('../fonts/EugenioSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Sans';
    src: url('../fonts/EugenioSans-Light.eot');
    src:
        url('../fonts/EugenioSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSans-Light.woff2') format('woff2'),
        url('../fonts/EugenioSans-Light.woff') format('woff'),
        url('../fonts/EugenioSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eugenio Sans';
    src: url('../fonts/EugenioSans-Bold.eot');
    src:
        url('../fonts/EugenioSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/EugenioSans-Bold.woff2') format('woff2'),
        url('../fonts/EugenioSans-Bold.woff') format('woff'),
        url('../fonts/EugenioSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
